import PrimaryButton from '../Buttons/PrimaryButton'
import Tooltip from '../Tooltip/Tooltip'
import { Link } from 'react-router-dom'
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner'
import MenuItem from '../MenuItem/MenuItem'
import InfoMessage from '../InfoMessage/InfoMessage'
import { addRP, addRPToOpen } from '../../utils/utils'
import { useState } from 'react'

export default function AddRP({ characters = [], fetchRPs, closeModal }) {
    const [postIsLoading, setPostIsLoading] = useState(false)
    const [newLastDate, setNewLastDate] = useState('')
    const [newRPLink, setNewRPLink] = useState('')
    const [newRPName, setNewRPName] = useState('')
    const [newToOpenName, setNewToOpenName] = useState('')
    const [newToOpenCharacter, setNewToOpenCharacter] = useState('')
    const [newToOpenDescription, setNewToOpenDescription] = useState('')
    const [selectedCharacter, setSelectedCharacter] = useState('')
    const [hasError, setHasError] = useState(false)
    const [isClosed, setIsClosed] = useState(false)
    const [activeTab, setActiveTab] = useState('RP en cours')

    const handleIsClosed = () => {
        setIsClosed(!isClosed)
    }

    const triggerError = () => {
        setHasError(true)
    }

    const handleCharacterChoice = (e) => {
        activeTab === 'RP en cours'
            ? setSelectedCharacter(e.target.value)
            : setNewToOpenCharacter(e.target.value)
    }

    const postRP = async () => {
        setPostIsLoading(true)
        try {
            await addRP(
                newRPLink,
                newRPName,
                selectedCharacter,
                newLastDate,
                isClosed
            )
            setNewRPLink('')
            fetchRPs()
            closeModal()
        } catch (_) {
            triggerError()
        } finally {
            setPostIsLoading(false)
        }
    }
    const postRPToOpen = async () => {
        setPostIsLoading(true)
        try {
            await addRPToOpen(
                newToOpenName,
                newToOpenCharacter,
                newToOpenDescription
            )
            fetchRPs()
            closeModal()
        } catch (_) {
            triggerError()
        } finally {
            setPostIsLoading(false)
        }
    }
    return (
        <div className="text-center mx-auto p-4 w-11/12">
            <div className="flex justify-center m-auto mt-6">
                <h3>Ajouter un RP</h3>
                <Tooltip tooltipStyle="sm:mt-[3px] mt-0" position={null}>
                    {activeTab === 'RP en cours' ? (
                        <>
                            <p>
                                Pour que vos RPs soient trackés correctement, il
                                faut que :
                            </p>
                            <ul className="list-disc ml-8 my-4">
                                <li>
                                    vos personnages sur Airpéger aient
                                    exactement le même nom que sur vos forums
                                </li>
                                <li>
                                    vous ajoutiez le lien de la 1ère page de
                                    votre RP
                                </li>
                            </ul>
                            <p>
                                Si vous avez une question ou un problème,
                                n'hésitez pas à me contacter pour qu'on regarde.
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                Notez ici vos RPs à ouvrir, ajoutez un nom et
                                une description afin de ne pas les oublier.
                            </p>
                            <p>
                                Il n'est pas encore possible de transformer un
                                RP à ouvrir en RP ouvert. Une fois votre RP
                                ouvert, vous pouvez supprimer le RP à ouvrir, et
                                ajouter le RP ouvert.
                            </p>
                        </>
                    )}
                </Tooltip>
            </div>
            {hasError && (
                <InfoMessage type="warning">
                    <p>
                        Une erreur est survenue au moment d'ajouter votre RP.
                        Veuillez ré-essayer.
                    </p>
                </InfoMessage>
            )}
            <ul className="w-full sm:flex justify-items-stretch mt-2">
                <MenuItem
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    testId="tracker-category"
                    name="RP en cours"
                    isFull>
                    RP en cours / terminé
                </MenuItem>
                <MenuItem
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    testId="tracker-category"
                    name="RP à ouvrir"
                    isFull>
                    RP à ouvrir
                </MenuItem>
            </ul>
            {activeTab === 'RP en cours' && (
                <div className="my-6">
                    <div className="sm:flex justify-between">
                        <div className="text-left sm:w-1/2 sm:mr-2">
                            <label htmlFor="rpLink" className="text-sm">
                                Lien du RP
                            </label>
                            <br />
                            <input
                                type="text"
                                id="rpLink"
                                name="Lien du RP"
                                placeholder="Lien de mon RP"
                                className="px-8 py-4 mb-4 w-full mt-2 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                                onChange={(e) => setNewRPLink(e.target.value)}
                                value={newRPLink}
                            />
                        </div>
                        <div className="text-left sm:w-1/2 sm:ml-2">
                            <label htmlFor="rpName" className="text-sm">
                                Nom du RP
                            </label>
                            <br />
                            <input
                                type="text"
                                id="rpName"
                                name="Nom de mon RP"
                                placeholder="Nom de mon RP"
                                className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                                onChange={(e) => setNewRPName(e.target.value)}
                                value={newRPName}
                            />
                        </div>
                    </div>
                    <div className="sm:flex justify-between">
                        <div className="text-left sm:w-1/2 sm:mr-2">
                            <label htmlFor="characterName" className="text-sm">
                                Nom du personnage
                            </label>
                            <br />
                            <select
                                id="characterName"
                                className="px-8 py-4 mb-4 mt-2 bg-background rounded-md border border-solid border-border-color focus:outline focus:outline-secondary w-full appearance-none"
                                onChange={handleCharacterChoice}
                                data-testid="character-select">
                                <option value="">
                                    Nom du personnage lié au RP
                                </option>
                                {characters.map((item, index) => (
                                    <option
                                        key={`${item}-${index}`}
                                        value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="text-left sm:w-1/2 sm:ml-2">
                            <label htmlFor="rpdate" className="text-sm">
                                Date de dernière réponse au RP
                            </label>
                            <br />
                            <input
                                id="rpdate"
                                type="date"
                                name="Date de dernière réponse"
                                placeholder="Date de dernière réponse"
                                className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary mt-2"
                                onChange={(e) => setNewLastDate(e.target.value)}
                                value={newLastDate}
                            />
                        </div>
                    </div>
                    <div className="flex items-center my-1 mb-6">
                        <input
                            className="mr-3 w-5 h-5 status-filter"
                            type="checkbox"
                            checked={isClosed}
                            onChange={handleIsClosed}
                        />
                        <label htmlFor="isClosed">Ce RP est terminé</label>
                    </div>
                    <Link
                        to="/personnages"
                        onClick={closeModal}
                        className="text-secondary mt-3">
                        Gérer mes personnages
                    </Link>
                </div>
            )}
            {activeTab === 'RP à ouvrir' && (
                <div className="my-6">
                    <div className="sm:flex justify-between">
                        <div className="text-left sm:w-1/2 sm:mr-2">
                            <label htmlFor="characterName" className="text-sm">
                                Nom du personnage
                            </label>
                            <br />
                            <select
                                id="characterName"
                                className="px-8 py-4 mb-4 mt-2 bg-background rounded-md border border-solid border-border-color focus:outline focus:outline-secondary w-full appearance-none"
                                onChange={handleCharacterChoice}
                                data-testid="character-select">
                                <option value="">
                                    Nom du personnage lié au RP
                                </option>
                                {characters.map((item, index) => (
                                    <option
                                        key={`${item}-${index}`}
                                        value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="text-left sm:w-1/2 sm:ml-2">
                            <label htmlFor="rpToOpenName" className="text-sm">
                                Nom du RP à ouvrir
                            </label>
                            <br />
                            <input
                                type="text"
                                id="rpToOpenName"
                                name="Nom de mon RP"
                                placeholder="Nom du RP à ouvrir"
                                className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                                onChange={(e) =>
                                    setNewToOpenName(e.target.value)
                                }
                                value={newToOpenName}
                            />
                        </div>
                    </div>
                    <div className="text-left w-full">
                        <label htmlFor="rpDescription" className="text-sm">
                            Description du RP à ouvrir
                        </label>
                        <br />
                        <textarea
                            type="text"
                            id="rpDescription"
                            name="Description de mon RP à ouvrir"
                            placeholder="Descriptions et notes sur le RP à ouvrir"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary h-28"
                            onChange={(e) =>
                                setNewToOpenDescription(e.target.value)
                            }
                            value={newToOpenDescription}
                        />
                    </div>
                    <Link
                        to="/personnages"
                        onClick={closeModal}
                        className="text-secondary mt-3">
                        Gérer mes personnages
                    </Link>
                </div>
            )}
            <div className="flex justify-center sm:pb-4 pb-8">
                <PrimaryButton
                    clickFunc={
                        activeTab === 'RP en cours' ? postRP : postRPToOpen
                    }>
                    {postIsLoading ? <ButtonSpinner /> : 'Ajouter le RP'}
                </PrimaryButton>
            </div>
        </div>
    )
}
