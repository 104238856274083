import PrimaryButton from '../Buttons/PrimaryButton'
import Tooltip from '../Tooltip/Tooltip'
import InfoMessage from '../InfoMessage/InfoMessage'
import { Link } from 'react-router-dom'
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner'
import { updateRP } from '../../utils/utils'
import { useState } from 'react'

export default function UpdateRP({
    characters = [],
    fetchRPs,
    closeModal,
    rpToUpdate,
}) {
    const [postIsLoading, setPostIsLoading] = useState(false)
    const [newRPName, setNewRPName] = useState('')
    const [newLastDate, setNewLastDate] = useState('')
    const [selectedCharacter, setSelectedCharacter] = useState('')
    const [hasError, setHasError] = useState(false)
    const [isClosed, setIsClosed] = useState(rpToUpdate.isClosed)

    const handleIsClosed = () => {
        setIsClosed(!isClosed)
    }

    const triggerError = () => {
        setHasError(true)
    }

    const handleCharacterChoice = (e) => {
        setSelectedCharacter(e.target.value)
    }

    const modifyRP = async () => {
        setPostIsLoading(true)
        try {
            await updateRP(
                rpToUpdate.link,
                newRPName || rpToUpdate.name,
                selectedCharacter || rpToUpdate.character,
                newLastDate || rpToUpdate.lastDate,
                isClosed
            )
            fetchRPs()
            closeModal()
        } catch (_) {
            triggerError()
        } finally {
            setPostIsLoading(false)
        }
    }
    return (
        <div className="text-center m-auto p-4 w-11/12">
            <div className="flex justify-center m-auto mt-4">
                <h3>Modifier un RP</h3>
                <Tooltip tooltipStyle="sm:mt-[3px] mt-4" position={null}>
                    <p>
                        Il n'est pas possible de modifier l'URL du rp que vous
                        suivez. Si vous souhaitez modifier son lien, supprimez
                        le rp de vos rps suivis, et suivez le une nouvelle fois.
                    </p>
                </Tooltip>
            </div>
            {hasError && (
                <InfoMessage type="warning">
                    <p>
                        Une erreur est survenue au moment de modifier votre RP.
                        Veuillez ré-essayer.
                    </p>
                </InfoMessage>
            )}
            <div className="my-6">
                <div className="sm:flex justify-between">
                    <div className="text-left sm:w-1/2 sm:mr-2">
                        <label htmlFor="rpLink" className="text-sm">
                            Lien du RP
                        </label>
                        <br />
                        <input
                            type="text"
                            id="rpLink"
                            name="Lien du RP"
                            placeholder="Lien de mon RP"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary bg-disabled"
                            value={rpToUpdate.link}
                            disabled
                        />
                    </div>
                    <div className="text-left sm:w-1/2 sm:mr-2">
                        <label htmlFor="rpName" className="text-sm">
                            Nom du RP
                        </label>
                        <br />
                        <input
                            type="text"
                            id="rpName"
                            name="Nom de mon RP"
                            placeholder="Nom de mon RP"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) => setNewRPName(e.target.value)}
                            value={newRPName || rpToUpdate.name}
                        />
                    </div>
                </div>
                <div className="sm:flex justify-between">
                    <div className="text-left sm:w-1/2 sm:mr-2">
                        <label htmlFor="characterName" className="text-sm">
                            Nom du personnage
                        </label>
                        <select
                            className="px-8 py-4 mb-4 mt-2 w-full bg-background rounded-md border border-solid border-border-color focus:outline focus:outline-secondary appearance-none"
                            onChange={handleCharacterChoice}
                            id="characterName"
                            data-testid="character-select">
                            {!rpToUpdate.character && (
                                <option value="">
                                    Nom du personnage lié au RP
                                </option>
                            )}
                            {characters.map((item, index) => (
                                <option
                                    key={`${item}-${index}`}
                                    value={item}
                                    selected={item === rpToUpdate.character}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="text-left sm:w-1/2 sm:mr-2">
                        <label htmlFor="rpdate" className="text-sm">
                            Date de dernière réponse au RP
                        </label>
                        <br />
                        <input
                            id="rpdate"
                            data-testid="rpdate"
                            type="date"
                            name="Date de dernière réponse"
                            placeholder="Date de dernière réponse"
                            className="px-8 py-4 mb-4 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary mt-2"
                            onChange={(e) => setNewLastDate(e.target.value)}
                            value={newLastDate || rpToUpdate.lastDate}
                        />
                    </div>
                </div>
                <div className="flex items-center my-1 mb-6">
                    <input
                        className="mr-3 w-5 h-5 status-filter"
                        type="checkbox"
                        checked={isClosed}
                        onChange={handleIsClosed}
                    />
                    <label htmlFor="isClosed">Ce RP est terminé</label>
                </div>
                <Link
                    onClick={closeModal}
                    to="/personnages"
                    className="text-secondary">
                    Gérer mes personnages
                </Link>
            </div>
            <div className="flex justify-center sm:pb-4 pb-8">
                <PrimaryButton clickFunc={modifyRP}>
                    {postIsLoading ? <ButtonSpinner /> : 'Modifier le RP'}
                </PrimaryButton>
            </div>
        </div>
    )
}
