import close from '../../Assets/close.svg'
import closedark from '../../Assets/close-dark.png'
import PrimaryButton from '../Buttons/PrimaryButton'
import SecondaryButton from '../Buttons/SecondaryButton'
import Warning from '../Warning/Warning'
import InfoMessage from '../InfoMessage/InfoMessage'
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner'

import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'
import AddRP from '../AddRP/AddRP'
import UpdateRP from '../UpdateRP/UpdateRP'

export default function Confirmation({
    closeModal,
    publishRP,
    warning,
    error,
    postIsLoading,
    source,
    characters,
    fetchRPs,
    rpToUpdate,
}) {
    const cancelClick = () => {
        closeModal(false)
    }
    const { theme } = useTheme()

    return (
        <div className="absolute top-0 left-0 h-full w-full bg-black/70">
            <div className="bg-main-background text-main bg-h-fit sm:w-2/3 fixed sm:top-12 top-8 left-0 right-0 m-auto rounded-md flex p-4 border border-border-color border-solid mx-2 sm:m-auto overflow-auto sm:h-[85%] h-[90%]">
                <img
                    src={lightThemes.includes(theme) ? close : closedark}
                    alt="fermer la fenêtre"
                    className="w-4 h-4 absolute top-4 right-4 cursor-pointer"
                    onClick={cancelClick}
                />
                {source === 'write' && (
                    <div className="text-center m-auto p-4">
                        <h3>Avez-vous bien posté votre RP ?</h3>
                        {!warning ? (
                            <Warning>
                                Attention, vous n'avez apparemment pas validé
                                les 4 étapes dans la rédaction de votre RP.
                                Pensez à vérifier que vous n'avez rien oublié :
                                personnage lié, texte du RP, design du RP et RP
                                copié puis posté.
                            </Warning>
                        ) : null}
                        <p>
                            En cliquant sur "J'ai posté mon RP", votre brouillon
                            sera supprimé. Ne cliquez sur ce bouton que quand
                            vous aurez publié le RP !
                        </p>
                        {error && (
                            <InfoMessage type="warning">
                                <p>
                                    Une erreur est survenue au moment de poster
                                    votre RP. Veuillez ré-essayer.
                                </p>
                            </InfoMessage>
                        )}
                        <div className="mt-6 flex sm:flex-row flex-col items-center justify-center">
                            <SecondaryButton clickFunc={cancelClick}>
                                Oups, pas encore
                            </SecondaryButton>
                            <PrimaryButton clickFunc={publishRP}>
                                {postIsLoading ? (
                                    <ButtonSpinner />
                                ) : (
                                    "Oui, c'est bon !"
                                )}
                            </PrimaryButton>
                        </div>
                    </div>
                )}
                {source === 'add' && (
                    <AddRP
                        characters={characters}
                        fetchRPs={fetchRPs}
                        closeModal={closeModal}
                    />
                )}
                {source === 'update' && (
                    <UpdateRP
                        characters={characters}
                        fetchRPs={fetchRPs}
                        closeModal={closeModal}
                        rpToUpdate={rpToUpdate}
                    />
                )}
            </div>
        </div>
    )
}
