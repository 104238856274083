import { useState, useEffect } from 'react'

import PrimaryButton from '../../../Components/Buttons/PrimaryButton'
import Tooltip from '../../../Components/Tooltip/Tooltip'
import CharacterItem from '../../../Components/CharacterItem/CharacterItem'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import Spinner from '../../../Components/Spinner/Spinner'
import ButtonSpinner from '../../../Components/ButtonSpinner/ButtonSpinner'

import {
    getCharacters,
    addCharacter,
    deleteCharacter,
} from '../../../utils/utils'

export default function Characters() {
    const [characters, setCharacters] = useState([])
    const [newCharacterName, setNewCharacterName] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [postIsLoading, setPostIsLoading] = useState(false)

    const fetchCharacters = async () => {
        setIsLoading(true)
        try {
            const { data } = await getCharacters()
            setCharacters(data)
        } catch (_) {
            setCharacters([])
        } finally {
            setIsLoading(false)
        }
    }

    const postCharacter = async () => {
        setPostIsLoading(true)
        try {
            await addCharacter(newCharacterName)
            fetchCharacters()
        } catch (_) {
            throw new Error('Erreur')
        } finally {
            setPostIsLoading(false)
        }
    }

    const removeCharacter = async (characterToDelete) => {
        try {
            await deleteCharacter(characterToDelete)
            fetchCharacters()
        } catch (_) {
            throw new Error('Erreur')
        }
    }

    useEffect(() => {
        fetchCharacters()
    }, [])

    return (
        <>
            <h1 className="sm:ml-4">Mes Personnages</h1>
            <div className="my-8 sm:flex items-center">
                <input
                    type="text"
                    name="Nom du personnage"
                    placeholder="Nom de mon personnage"
                    className="px-8 py-4 mr-4 sm:w-80 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    onChange={(e) => setNewCharacterName(e.target.value)}
                />
                <div className="flex items-center">
                    <PrimaryButton clickFunc={postCharacter}>
                        {postIsLoading ? (
                            <ButtonSpinner />
                        ) : (
                            'Ajouter le personnage'
                        )}
                    </PrimaryButton>
                    <Tooltip tooltipStyle="sm:mt-0 mt-4">
                        <p>
                            Ajoutez vos personnages en indiquant leur pseudo
                            exact sur votre forum. Cela permettra de suivre le
                            statut de vos RPs !
                        </p>
                    </Tooltip>
                </div>
            </div>
            {!isLoading ? (
                characters.length > 0 ? (
                    characters.map((item, index) => (
                        <CharacterItem
                            key={`${item}-${index}`}
                            character={item}
                            removeCharacter={removeCharacter}
                        />
                    ))
                ) : (
                    <InfoMessage>
                        Vous n'avez pas encore ajouté de personnage à votre
                        compte. Ajoutez votre premier personnage grâce au
                        formulaire ci-dessus !
                    </InfoMessage>
                )
            ) : (
                <Spinner centered margin />
            )}
        </>
    )
}
